
import { Record, Schema } from 'js-data'

import store from '@/api'

export class JobRecord extends Record {
}

export const STATUS = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'InProgress',
  FAILED: 'Failed',
}

const JobSchema = new Schema({
  title: 'JobSchemaTitle',
  description: 'Schema for Job Records.',
  type: 'object',
  properties: {
  },
  additionalProperties: true,
})

export const Job = store.defineMapper('Job', {
  schema: JobSchema,
  endpoint: 'jobs',
  relations: {
    belongsTo: {
      AppUser: {
        localKey: 'userId',
        localField: 'user',
      },
    },
  },
  recordClass: JobRecord,
})
