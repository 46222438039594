import 'core-js/actual'
import 'regenerator-runtime/runtime'
import { getQueryStringAsObject } from '@/helpers/params.js'
import Vue from 'vue'
import moment from 'moment'
import numeral from 'numeral'
import { findIndex, isEqual } from 'lodash'
import ElementUI from 'element-ui' // http://element.eleme.io
import AsyncComputed from 'vue-async-computed'
import router from '@/router.js'
import store from './store/index.js'
import VueLocalStorage from 'vue-localstorage'
import VueMq from 'vue-mq'
import HTML from 'vue-html'
import VueIntro from 'vue-introjs'
import VueSlider from 'vue-slider-component'
import vuescroll from 'vuescroll'

// La librairie de base adapter par vue-instant-pdf-viewer
import './helpers/pdfobject/pdfobject.js'
// Créer window.PDFObject pour qu'il puisse être utilisé par vue-instant-pdf-viewer
import VueScrollTo from 'vue-scrollto'
import PortalVue from 'portal-vue'
import VueFroala from 'vue-froala-wysiwyg'
import i18n from '@/i18n'
// Assets
import './assets/style/reset.sass'
import './assets/style/introJs/introJs.css'
import './assets/style/index.scss'
import './assets/fonts/Pi.font.js'
import './assets/fonts/PiJobs.font.js'
import './assets/fonts/PiCompetencies.font.js'
import './assets/fonts/PiTeacher.font.js'
import './assets/fonts/font-awesome.font.js'
import './assets/style/pixine/index.sass'
import 'vue-slider-component/theme/antd.css'

import UtilMixin from '@/mixins/Utils.js'
import ContactMixin from '@/mixins/Contact.js'
import CreditsMixin from '@/mixins/Credits.js'
import IdCardMixin from '@/mixins/IdCard.js'
import PermissionsMixin from '@/mixins/Permissions.js'
import ContextMixin from '@/mixins/Context.js'

import LargeButton from '@/components/Templates/LargeButton/large-button.vue'
import BackButton from '@/components/Templates/BackButton/back-button.vue'
import UiGlobalsActions from '@/components/Templates/GlobalsActions/ui-globals-actions.vue'
import Tag from '@/components/Templates/Tag/Tag.vue'
import Tooltip from '@/components/Templates/Tooltip/Tooltip.vue'
import PiContactUser from '@/components/Contact/User.vue'
import PiContactCompany from '@/components/Contact/Company.vue'
import PiIdCard from '@/components/Templates/PiIdCard/PiIdCard.vue'
import PiSeeLessonPageCorrection from '@/components/Templates/PiSeeLessonPageCorrection/PiSeeLessonPageCorrection.vue'
import PiCredits from '@/components/Wallets/PiCredits.vue'
import PiUploads from '@/components/Templates/PiUploads/Pi-Uploads.vue'
import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'
import PiUploadsPreview from '@/components/Templates/PiUploads/Preview.vue'
import ClickOutside from 'vue-click-outside'
import PiChart from '@/components/Classes/Chart.vue'
import './helpers/context'
import VueObserveVisibility from 'vue-observe-visibility'
import VueUploadComponent from 'vue-upload-component'

import Alive from '@/plugins/alive/index.js'
const App = () => import('./App')
require('froala-editor/js/froala_editor.pkgd.min')
require('froala-editor/css/froala_editor.pkgd.min.css')
require('font-awesome/css/font-awesome.css')
require('froala-editor/js/languages/fr')

const query = getQueryStringAsObject()
if (query.idT && query.idU) {
  const idToken = query.idT
  const idUser = query.idU
  window.localStorage.setItem('id_token', idToken)
  window.localStorage.setItem('id_user', idUser)
}
Vue.mixin(UtilMixin)
Vue.mixin(ContactMixin)
Vue.mixin(CreditsMixin)
Vue.mixin(IdCardMixin)
Vue.mixin(PermissionsMixin)
Vue.mixin(ContextMixin)
Vue.component('pi-contact-user', PiContactUser)
Vue.component('pi-contact-company', PiContactCompany)
Vue.component('pi-see-lesson-page-correction', PiSeeLessonPageCorrection)
Vue.component('pi-id-card', PiIdCard)
Vue.component('pi-pop', PiPop)
Vue.component('pi-uploads', PiUploads)
Vue.component('pi-uploads-preview', PiUploadsPreview)
Vue.component('pi-credits', PiCredits)
Vue.component('tag', Tag)
Vue.component('tooltip', Tooltip)
Vue.component('pi-chart', PiChart)
Vue.component('ui-globals-actions', UiGlobalsActions)
Vue.component('large-button', LargeButton)
Vue.component('back-button', BackButton)
Vue.component('vue-slider', VueSlider)
Vue.component('file-upload', VueUploadComponent)

Vue.component('v-style', {
  render (createElement) {
    return createElement('style', this.$slots.default)
  },
})

Vue.directive('click-outside', ClickOutside)
Vue.directive('scroll', {
  inserted (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        el.removeEventListener('scroll', f)
      }
    }
    el.addEventListener('scroll', f)
  },
})

// Gestion des erreurs des requêtes asynchrones
const confError = {
  errorHandler: (errors) => {
    console.error(errors)
  },
}
// Enregistrement de la page demandée :
window.sessionStorage.setItem('main.request.url', window.location.pathname)
// config numeraljs
moment.locale('fr')
moment.relativeTimeThreshold('h', 24)
// load a locale
numeral.register('locale', 'fr', {
  delimiters: {
    thousands: '\u00A0', // Espace insécable
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '€',
  },
})

// switch between locales
numeral.locale('fr')

// Plugins
ElementUI.Option.computed.itemSelected = function () {
  // console.log('ElementUI.Option.computed')
  if (!this.select.multiple) {
    return isEqual(this.value, this.select.value)
  } else {
    const scope = this
    const out = findIndex(this.select.value, function (entry) {
      return isEqual(entry, scope.value)
    })
    return out > -1
  }
}
ElementUI.Select.methods.getOption = function (value) {
  let option
  for (let i = this.cachedOptions.length - 1; i >= 0; i--) {
    const cachedOption = this.cachedOptions[i]
    if (isEqual(cachedOption.value, value)) {
      option = cachedOption
      break
    }
  }
  if (option) return option
  const label = typeof value === 'string' || typeof value === 'number' ? value : ''
  const newOption = {
    value,
    currentLabel: label,
  }
  if (this.multiple) {
    newOption.hitState = false
  }
  return newOption
}
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      mode: 'native',
      sizeStrategy: 'percent',
      detectResize: true,
      /** Enable locking to the main axis if user moves only slightly on one of them at start */
      locking: true,
    },
    rail: {
      size: '10px',
    },
    bar: {
      showDelay: 500,
      onlyShowBarOnScroll: true,
      keepShow: true,
      background: '#c1c1c1',
      opacity: 1,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: 0.125,
      size: '10px',
      disable: false,
    },
  },
})
Vue.use(VueFroala)
Vue.use(ElementUI)
Vue.use(AsyncComputed, confError)
Vue.use(VueLocalStorage)
Vue.use(HTML)
Vue.use(PortalVue)
Vue.use(VueObserveVisibility)

Vue.use(VueMq, {
  breakpoints: {
    // SASS : xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px
    xs: 575,
    sm: 767,
    md: 991,
    lg: 1199,
    xl: 1599,
    xl2: Infinity,
  },
})

Vue.use(Alive, {
  maintenance: [process.env.VUE_APP_API_GENERIC],
})

Vue.use(VueIntro)
Vue.use(VueScrollTo)

// Application
const app = window.mainApplication = new Vue({
  i18n,
  store,
  el: '#app',
  mounted () {
    this.$store.commit('intro/INSTALL_INTRO_JS', this.$intro)
  },
  render: h => h(App),
  router,
})

export default app
