import store from '@/api'
import utils from '@/helpers/utils.js'
import { Record, Schema } from 'js-data'
import { flatten, get, isArray, map, uniq } from 'lodash'
import { addActions } from 'js-data-http'

const mimeTypeToFileType = {
  'image/jpeg': 'image',
  'image/png': 'image',
  'image/gif': 'image',
  'application/pdf': 'pdf',
  'audio/mpeg': 'audio',
  'audio/mp3': 'audio',
  'video/mp4': 'video',
  'application/zip': 'tincan',
  'application/x-zip-compressed': 'tincan',
  tincan: 'tincan',
  external: 'external',
}

const fileTypeConfiguration = {
  image: {
    icon: 'fa-file-image-o',
    previewable: true,
  },
  picture: {
    icon: 'fa-file-image-o',
    previewable: false,
  },
  pdf: {
    icon: 'fa-file-pdf-o',
    previewable: false,
  },
  file: {
    icon: 'fa-file-o',
    previewable: false,
  },
  tincan: {
    icon: 'fa-file-o',
    previewable: true,
  },
  external: {
    icon: 'fa-globe',
    previewable: false,
  },
  archive: {
    icon: 'fa-file-archive-o',
    previewable: false,
  },
  audio: {
    icon: 'fa-file-audio-o',
    previewable: false,
  },
  video: {
    icon: 'fa-file-video-o',
    previewable: false,
  },
}
function typesToMimeTypes (types) {
  if (!isArray(types)) {
    return []
  }
  return uniq(flatten(map(types, function (type) {
    const mimeTypes = []
    for (const mime in mimeTypeToFileType) {
      if (mimeTypeToFileType[mime] === type) {
        mimeTypes.push(mime)
      }
    }
    return mimeTypes
  })))
}

export class AppFileRecord extends Record {
  get download () {
    return utils.getFilePath(this.id)
  }

  get fileType () {
    return get(mimeTypeToFileType, this.type, 'file')
  }

  get icon () {
    const type = get(mimeTypeToFileType, this.type, 'file')
    return get(fileTypeConfiguration, `${type}.icon`)
  }

  get fileNameWithoutExtension () {
    return this.name.replace(/\.[^/.]+$/, '')
  }

  infos () {
    return this.constructor.mapper.getInfos(this.id, {}).then(response => {
      return response.data
    })
  }
}

const AppFileSchema = new Schema({
  title: 'AppFileSchemaTitle',
  description: 'Schema for AppFile Records.',
  type: 'object',
  pi: {
    fileTypeConfiguration,
    mimeTypeToFileType,
    typesToMimeTypes,
    defaultTypes: ['image'],
  },
  properties: {
    name: {
      type: 'string',
    },
    type: {
      type: 'string',
    },
    key: {
      type: 'string',
    },
    tags: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    libraryId: {
      type: 'string',
    },
    ownerId: {
      type: 'string',
    },
    metas: {
      type: 'object',
      additionalProperties: true,
    },
    link: {
      type: 'string',
    },
  },
})

export const AppFile = store.defineMapper('AppFile', {
  schema: AppFileSchema,
  endpoint: 'files',
  relations: {
    belongsTo: {
      Library: {
        parent: true,
        localKey: 'libraryId',
        localField: 'library',
      },
    },
  },
  recordClass: AppFileRecord,
})

addActions({
  getInfos: {
    pathname: 'infos',
    method: 'GET',
  },
})(AppFile)
