
<template lang="pug">
.chart
  polarArea(
    v-if='formatedChartData.datasets'
    :data="formatedChartData"
    :options="options"
    :width="width / 2"
    :height="height / 2"
  )
</template>
<script>
import { PolarArea } from 'vue-chartjs'
import {
  chunk,
  compact,
  get,
  isEmpty,
  isNumber,
} from 'lodash'

import {
  ArcElement,
  BarElement,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  RadialLinearScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
)

export default {
  name: 'pi-chart',
  components: {
    PolarArea,
  },
  props: {
    width: {},
    height: {},
    scores: {
      type: Object,
      default: function () {
        return {}
      },
    },
    chartData: {
      type: Object,
      default: function () {
        return {}
      },
    },
    property: {
      type: String,
      default: 'CourseCompetency',
    },
    iteratee: {},
    ratioLimit: {
      type: [Boolean, Number],
      default: false,
    },
    onlyWithScore: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      options: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            boxPadding: 5,
            padding: 10,

            titleFontSize: 18,
            titleSpacing: 6,
            titleMarginBottom: 10,

            bodyFontSize: 18,
            bodySpacing: 6,

            footerFontSize: 18,
            footerSpacing: 6,
            footerMarginTop: 10,
            caretPadding: 4,
            callbacks: {
              label: (e) => e.dataset.isEvaluated[e.dataIndex] === false ? 'Non évaluée' : e.formattedValue,
            },
          },
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
          },
        },
        scales: {
          r: {
            min: -10,
            max: 100,
            pointLabels: {
              display: true,
              centerPointLabels: true,
              font: {
                size: 12,
              },
            },
          },
        },
        onClick: (e) => {
          const points = e.chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
          const index = points[0]?.index
          if (index === undefined) {
            this.$emit('clickOutside')
            return
          }
          const id = this.formatedChartData.ids[index]
          if (id === undefined) {
            this.$emit('clickOutside')
            return
          }
          this.$emit('clickArea', id)
        },
      },
    }
  },
  computed: {
    formatedRatioLimit () {
      if (isNumber(this.ratioLimit)) {
        return this.ratioLimit
      } else {
        return null
      }
    },
    formatedIteratee () {
      return compact([].concat(this.iteratee))
    },
    formatedChartData () {
      if (isEmpty(this.formatedIteratee) || isEmpty(this.scores)) {
        return {}
      }
      const labels = []
      const ids = []
      const data = []
      const backgroundColor = []
      const pointBorderColor = []
      const isEvaluated = []
      for (const i in this.formatedIteratee) {
        const { id } = this.formatedIteratee[i]

        if (this.onlyWithScore && !Object.keys(this.scores).includes(id)) {
          continue
        }

        let label = get(this.$store.getters, [`DS${this.property}`, id, 'title'], id).split(' ')
        label = chunk(label, 2).map(el => el.join(' '))
        const d = Math.round(this.getRatio(this.scores[id], this.formatedRatioLimit) * 100)

        labels.push(label)

        ids.push(id)

        data.push(d)
        if (Object.keys(this.scores).includes(id)) {
          backgroundColor.push('rgba(32, 160, 255, .2)')
          isEvaluated.push(true)
        } else {
          backgroundColor.push('rgba(0, 0, 0, .2)')
          isEvaluated.push(false)
        }
      }
      const datasets = [
        {
          data,
          pointBorderColor,
          backgroundColor,
          isEvaluated,
        },
      ]

      return {
        ids,
        labels,
        datasets,
      }
    },
  },
}
</script>

<style lang="sass">
.pi-chart
  canvas
    max-width: 100% !important
    height: auto !important
</style>
