
import { Record, Schema } from 'js-data'
import store from '@/api'
import { addActions } from 'js-data-http'

class TransactionRecord extends Record {
  get lesson () {
    if (this.targetId) {
      return store.get('Lesson', this.targetId)
    }
    return {}
  }

  verify () {
    console.log('[ VERIFY ]')
    return this.constructor.mapper.verify(this.id, {},
    ).then(response => {
      return response
    })
  }
}

const TransactionSchema = new Schema({
  title: 'TransactionSchemaTitle',
  description: 'Schema for Transaction Records.',
  type: 'object',
  properties: {
    amount: {
      type: 'number',
    },
    label: {
      type: 'string',
    },
    payload: {
      type: 'object',
      additionalProperties: true,
    },
    refundedAmount: {
      type: 'number',
    },
    walletId: {
      type: 'string',
    },
    targetId: {
      type: 'string',
    },
    targetType: {
      type: 'string',
    },
    updatedAt: {
      type: 'string',
    },
    createdAt: {
      type: 'string',
    },
    type: {
      type: 'string',
      enum: ['manual', 'paid', 'refund', 'consumed', 'external', 'upcoming'],
    },
    state: {
      type: 'string',
      enum: ['initial', 'pending', 'applied', 'done', 'canceling', 'canceled', 'ready', 'failed', 'upcoming'],
    },
    refundedTransactionIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  additionalProperties: true,
})

export const Transaction = store.defineMapper('Transaction', {
  schema: TransactionSchema,
  endpoint: 'transactions',
  afterCreate: function (props, options, record) {
    if (record && record.walletId && store.get('Wallet', record.walletId)) {
      store.find('Wallet', record.walletId, { force: true })
    }
    return record
  },
  relations: {
    belongsTo: {
      Wallet: {
        // parent: true,
        loadAllDatasWhenEditing: false,
        localKey: 'walletId',
        localField: 'wallet',
      },
    },
  },
  recordClass: TransactionRecord,
})

addActions({
  byContext: {
    pathname: 'context',
    method: 'GET',
  },
  verify: {
    pathname: 'verify',
    method: 'POST',
  },
})(store.getMapper('Transaction'))
