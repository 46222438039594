
import { Record, Schema } from 'js-data'

import store from '@/api'

export class ReviewRecord extends Record {
  static ratioToNote (ratio = 0) {
    return Math.max(Math.min((ratio || 0) * 5, 5), 0)
  }

  forModel (type, id) {
    return store.findAll(Review.name, { filter: { resourceType: type, resourceId: id } }, { force: true })
  }
}

const ReviewSchema = new Schema({
  title: 'ReviewSchemaTitle',
  description: 'Schema for Review Records.',
  type: 'object',
  properties: {
    comment: {
      type: 'string',
    },
    userId: {
      type: 'string',
    },
    classId: {
      type: 'string',
    },
    resourceType: {
      type: 'string',
    },
    resourceId: {
      type: 'string',
    },
    ratio: {
      type: 'number',
    },
  },
  additionalProperties: true,
})

export const Review = store.defineMapper('Review', {
  schema: ReviewSchema,
  endpoint: 'reviews',
  relations: {
    belongsTo: {
      AppUser: {
        localKey: 'userId',
        localField: 'user',
      },
    },
  },
  recordClass: ReviewRecord,
})
