
import { Record, Schema } from 'js-data'
import store from '@/api'
import moment from 'moment'

class RecordRecord extends Record {
  get dateMoment () {
    return moment(this.date)
  }
}

const RecordSchema = new Schema({
  title: 'RecordSchemaTitle',
  description: 'Schema for Record.',
  type: 'object',
  properties: {
    userId: {
      type: 'string',
    },
    date: {
      type: 'string',
    },
  },
  additionalProperties: true,
})

export const PiScoreRecord = store.defineMapper('Record', {
  schema: RecordSchema,
  endpoint: 'records',
  relations: {
    belongsTo: {
      AppUser: {
        localKey: 'userId',
        localField: 'user',
      },
    },
  },
  recordClass: RecordRecord,
})
