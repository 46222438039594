import { Record, Schema } from 'js-data'
import { addAction } from 'js-data-http'
import { compact, find, get, isArray } from 'lodash'
import store from '@/api'
import resolveDeps from '../getDeps.js'
import utils from '@/helpers/utils.js'
import { CURRENCIES } from './Wallet.js'
import { ROLES } from '../enums/roles.js'

const pattern = {
  name: '',
  legal: {
    siret: '',
  },
  address: {
    house: '',
    city: '',
    postalNumber: '',
  },
  avatarId: null,
  defaultCurrency: null,
}

const isSiret = (rule, value, callback) => {
  if (!utils.isSiret(value)) {
    callback(new Error('Numéro Siret invalide'))
  } else {
    callback()
  }
}
class CompanyRecord extends Record {
  contact (message, receiveCopy, subject, _roles, attachments = []) {
    message = utils.textToHtml(message)
    let roles = isArray(_roles) ? _roles : [_roles]
    roles = compact(roles)
    return this.constructor.mapper.contact(this.id, { data: { message, receiveCopy, subject, roles, attachments } })
  }

  usersByRole (filter = {}, role = [], options = {}, sort = []) {
    const _sort = isArray(sort) ? sort : [sort]
    return this.constructor.mapper.usersByRole(this.id, {
      params: {
        role: JSON.stringify(role),
        filter: JSON.stringify(filter),
        options: JSON.stringify({ ...options, sort: _sort }),
      },
    }).then(response => {
      response.data = response.data.map(el => {
        el.id = el._id
        return store.add('AppUser', el)
      })
      return response
    })
  }

  availablePrograms (isCertified) {
    return this.constructor.mapper.availablePrograms(this.id, {
      params: {
        isCertified,
      },
    }).then(response => {
      const datas = get(response, 'data', [])
      const out = datas.map(el => {
        return store.add('Program', el)
      })
      return out
    })
  }

  createInvitationCodes (count = 1) {
    return this.constructor.mapper.createInvitationCodes(this.id, {
      data: {
        count,
      },
    }).then(response => {
      const codes = []
      get(response, 'data', []).forEach(el => {
        codes.push(store.add('CompanyInvitationCode', el))
      })
      return codes
    })
  }

  downloadInvitationCodes (filter = {}) {
    return this.constructor.mapper.downloadInvitationCodes(this.id, {
      params: {
        access_token: window.localStorage.getItem('id_token'),
        filter: JSON.stringify(filter),
      },
      request: ({ url, params }) => {
        const fullurl = new URL(url)
        Object.keys(params).forEach(key => {
          fullurl.searchParams.set(key, params[key])
        })
        window.open(fullurl.href, `CompanyInvitationCode_${this.id}`)
        throw (new Error('abort'))
      },
    }).catch(() => { /* silent */ })
  }

  programsFilters (isCertification) {
    return this.constructor.mapper.programsFilters(this.id, {}).then(response => {
      console.log(response)
    })
  }

  invite (email, role) {
    let data = [{ email, role }]
    if (isArray(email)) {
      data = email.map(e => ({ email: e, role }))
    }
    return this.constructor.mapper.invite(this.id, {
      data,
    }).then(response => {
      const users = get(response, 'data.data', []).map(user => store.add('AppUser', user))
      if (get(response, 'data.errors', []).length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw ({
          data: users,
          errors: get(response, 'data.errors', []),
        })
      }
      return { data: users }
    })
  }

  allTransactions (filter = {}, options = {}, sort = []) {
    return this.constructor.mapper.allTransactions(this.id, {
      params: {
        filter: JSON.stringify({ type: { $in: ['paid', 'consumed', 'manual'] }, ...filter }),
        options: JSON.stringify(options),
        sort,
        order: sort,
      },
    }).then(response => {
      response.data = response.data.map(el => store.add('Transaction', el))
      return response
    })
  }

  get editPath () {
    return { name: 'CompanyEdit', params: { id: this.id } }
  }

  get currencyInUse () {
    if (this.defaultCurrency) {
      return this.defaultCurrency
    }
    return (this.walletLicense && this.walletLicense.fund) ? CURRENCIES.LICENSE : CURRENCIES.CREDIT
  }

  get walletCredit () {
    return find(this.wallets, { type: 'local', currency: CURRENCIES.CREDIT })
  }

  get walletLicense () {
    return find(this.wallets, { type: 'local', currency: CURRENCIES.LICENSE })
  }

  get walletForPayment () {
    return find(this.wallets, { type: 'external' })
  }

  get walletInUse () {
    return (this.currencyInUse === CURRENCIES.LICENSE) ? this.walletLicense : this.walletCredit
  }

  get walletsIds () {
    const creditWallet = this.walletCredit
    const licenseWallet = this.walletLicense
    return [creditWallet.id, licenseWallet.id]
  }

  get getOwners () {
    return store.findAll('CompanyUser', { filter: { companyId: this.id, role: ROLES.OWNER }, populate: 'user' }, { force: true, raw: true }).then(response => {
      return response.data.map(companyUser => companyUser.user._id)
    })
  }

  get getDeps () {
    const promises = {
      Program: store.findAll('ProgramCompany', { filter: { companyId: this.id }, populate: 'program', options: { skip: 0, limit: 10 } }, { force: true, raw: true }).then((response) => {
        const programIds = response.data.map(pc => pc.program?._id).filter(Boolean)
        const programs = store.filter('Program', { where: { _id: { in: programIds } } })

        return { data: programs, count: +response.headers['x-model-count'] }
      }),
      AppUser: store.findAll('CompanyUser', { filter: { companyId: this.id }, populate: 'user', options: { skip: 0, limit: 10 } }, { force: true, raw: true }).then((response) => {
        const userIds = response.data.map(cu => cu.user._id)
        const users = store.filter('AppUser', { where: { _id: { in: userIds } } })
        return { data: users, count: +response.headers['x-model-count'] }
      }),
    }
    return resolveDeps(promises)
  }
}

const CompanySchema = new Schema({
  title: 'CompanySchemaTitle',
  description: 'Schema for Company Records.',
  type: 'object',
  properties: {
    name: {
      type: 'string',
      form: {
        rules: [
          { required: true, message: 'Merci de la raison sociale', trigger: 'blur' },
        ],
      },
    },
    legal: {
      type: 'object',
      properties: {
        siret: {
          type: 'string',
          form: {
            rules: [
              { required: true, message: 'Merci de renseigner le champ', trigger: 'blur' },
              { validator: isSiret, trigger: 'blur' },
            ],
          },
        },
      },
    },
    address: {
      type: 'object',
      properties: {
        house: {
          type: 'string',
          form: {
            rules: [
              { required: true, message: 'Merci de renseigner le numéro et le nom de la rue', trigger: 'blur' },
            ],
          },
        },
        city: {
          type: 'string',
          form: {
            rules: [
              { required: true, message: 'Merci de renseigner la ville', trigger: 'blur' },
            ],
          },
        },
        postalNumber: {
          type: 'string',
          form: {
            rules: [
              { required: true, message: 'Merci de renseigner le code postal', trigger: 'blur' },
            ],
          },
        },
      },
    },
    avatarId: {
      type: ['string', 'null'],
    },
    defaultCurrency: {
      type: ['null', 'string'],
      enum: [...Object.values(CURRENCIES), null, undefined],
    },
  },
  required: ['legal.siret', 'name'],
})

export const Company = store.defineMapper('Company', {
  schema: CompanySchema,
  endpoint: 'companies',
  paths: {
    list: 'CompanyList',
    edit: 'CompanyEdit',
  },
  relations: {
    hasMany: {
      Wallet: {
        loadAllDatasWhenEditing: false,
        includeWhenEditing: true,
        localField: 'wallets',
        foreignKey: 'ownerId',
      },
      CompanyUser: {
        loadAllDatasWhenEditing: false,
        localField: 'companyUsers',
        foreignKey: 'companyId',
      },
      ProgramCompany: {
        loadAllDatasWhenEditing: false,
        localField: 'programCompanies',
        foreignKey: 'companyId',
      },
      Class: {
        loadAllDatasWhenEditing: false,
        localField: 'classes',
        localKey: 'companyId',
      },
    },
  },
  recordClass: CompanyRecord,
  pattern,
})
addAction('contact', {
  pathname: 'contact',
  method: 'POST',
})(Company)

addAction('invite', {
  pathname: 'invitation',
  method: 'POST',
})(Company)

addAction('createInvitationCodes', {
  pathname: 'invitation-codes/bulk-create',
  method: 'POST',
})(Company)

addAction('downloadInvitationCodes', {
  pathname: 'invitation-codes/bulk-download',
  method: 'POST',
})(Company)

// GET /companies/{id}/available-programs
addAction('availablePrograms', {
  pathname: 'available-programs',
  method: 'GET',
})(Company)

addAction('usersByRole', {
  pathname: 'users',
  method: 'GET',
})(Company)

addAction('allTransactions', {
  pathname: 'transactions',
  method: 'GET',
})(Company)

addAction('programsFilters', {
  pathname: 'programs/filters',
  method: 'GET',
})(Company)

addAction('register', {
  pathname: 'register',
  method: 'POST',
})(Company)
