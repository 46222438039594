/* eslint-disable camelcase */
import Vue from 'vue'
import router from '@/router.js'
import ConfigurationApi from '@/api/configuration.js'
import store from '@/api'
import {
  clone,
  compact,
  filter,
  forOwn,
  get,
  includes,
  isArray,
  map,
  property,
  set,
  uniq,
  without,
} from 'lodash'

import axios from 'axios'
import { deleteStorageDatasByKey } from '../../mixins/SessionStorage'
import { EVERY_ROLES, ROLES } from '@/api/enums/roles'
// URL and endpoint constants
const API_URL = () => ConfigurationApi.basePath
const USERS_URL = () => `${API_URL()}users/`
const LOGIN_URL = () => `${USERS_URL()}login`
const LOGIN_AS_URL = () => `${USERS_URL()}login-as`
const ME_URL = () => `${USERS_URL()}me`

// const LOGOUT_URL = USERS_URL + '/logout/'
const storage = window.localStorage
const ALLOWED_ROLES = without(EVERY_ROLES, ROLES.STUDENT)

function updateAdapter () {
  set(store.getAdapter().http.defaults.headers, 'common.Authorization', `Bearer ${storage.getItem('id_token')}`)

  if (process.env.VUE_APP_NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true') {
    if (ConfigurationApi['X-Host']) {
      store.getAdapter().http.defaults.headers.common['X-Host'] = ConfigurationApi['X-Host']
    }
    if (ConfigurationApi['X-Origin']) {
      store.getAdapter().http.defaults.headers.common['X-Origin'] = ConfigurationApi['X-Origin']
    }
  }
}
const vueHttpConfig = () => {
  const headers = {}

  if (process.env.VUE_APP_NODE_ENV !== 'production' && process.env.VUE_APP_LOCAL === 'true') {
    if (ConfigurationApi['X-Host']) {
      headers['X-Host'] = ConfigurationApi['X-Host']
    }

    if (ConfigurationApi['X-Origin']) {
      headers['X-Origin'] = ConfigurationApi['X-Origin']
    }
  }

  return { headers }
}
const state = () => ({
  // User object will let us check authentication status
  connecting: Boolean(storage.getItem('id_token') && storage.getItem('id_user')),
  acl: [],
  currentContextRoles: [],
  currentCompany: null,
  roles: [],
  permissions: [],
  user: {
    id: null,
    token: null,
    mail: null,
    authenticated: false,
    domain: null,
    legal: {},
    firstname: null,
    birthday: null,
  },
})
function setCurrentCompany (state, companyId) {
  if (companyId) {
    storage.setItem('user.currentCompany', companyId)
  } else {
    storage.removeItem('user.currentCompany')
  }
  Vue.set(state, 'currentCompany', companyId)
}

const mutations = {
  ACCESS (state, permissions) {
    state.acl.splice(0, state.acl.length)
    if (isArray(permissions)) {
      state.acl.push(...permissions)
    } else {
      state.acl.push(permissions)
    }
    Vue.prototype.$access(state.acl)
  },
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  SET_USER (state, user) {
    // @todo - on ne copie que les propriétés autorisées (sur les autres SETTERS)
    const properties = [
      'id',
      'email',
      'token',
      'createdAt',
      'firstname',
      'birthday',
      'lastname',
      'domain',
      'avatarId',
      'classUsers',
      'legal',
      'role-users',
    ]
    for (const property in user) {
      Vue.set(state.user, property, user[property])
    }
    properties.forEach(function (property) {
      if (user[property]) {
        Vue.set(state.user, property, user[property])
      }
    }, this)
    Vue.set(state, 'user', clone(state.user))
  },
  CONNECTING (state, connecting) {
    state.connecting = connecting
  },
  SET_USER_PERMISSIONS (state, permissions) {
    Vue.set(state.user, 'permissions', permissions)
  },
  SET_CURRENT_COMPANY: setCurrentCompany,
  SET_CURRENT_COMPANY_WITHOUT_WATCH: setCurrentCompany,
  SET_CURRENT_DOMAIN (state, domain) {
    Vue.set(state.user, 'domain', domain)
  },
  SET_USER_ROLES (state, roles) {
    Vue.set(state, 'roles', roles)
  },
  SET_USER_CONTEXT_ROLES (state, currentContextRoles) {
    Vue.set(state, 'currentContextRoles', currentContextRoles)
  },
  AUTHENTICATED (state, status) {
    if (!status) {
      Vue.set(state, 'user', {})
      storage.removeItem('id_token')
      storage.removeItem('id_user')
      storage.removeItem('ttl_token')
      storage.removeItem('created_token')
      storage.removeItem('user.currentCompany')
      window.sessionStorage.removeItem('main.request.url')
      deleteStorageDatasByKey('interface.')
    }
    Vue.set(state.user, 'authenticated', status)
  },
}
const getters = {
  CAN: (state) => {
    state.acl
    return (value) => Vue.prototype.$can(value)
  },
  acl: (state) => {
    return state.acl
  },
  needs_required_datas: (state, getters, rootState, rootGetters) => {
    const canDisplayRequiredDate = rootGetters['context/get']('settings.displayRequiredDatasOnStartup') ?? true
    return getters.isAuthenticated && canDisplayRequiredDate &&
      (
        !state.user.lastname ||
        !state.user.firstname ||
        !state.user.birthday ||
        get(state.user, 'legal.cguAcceptedVersion', false) !== rootGetters['context/get']('settings.legal.cgu.version')
      )
  },
  isClient: (state, getters) => {
    return !getters.isContextAdmin && getters.getPermissions.length > 0
  },
  isContextAdmin: (state, getters) => {
    const output = getters.hasRole('superadmin') || getters.hasRole('admin') || getters.hasRole('contextAdmin')
    if (!output) {
      window.sessionStorage.removeItem('useGenericContext')
    }
    return output
  },
  isAdmin: (state, getters) => {
    const output = getters.hasRole('superadmin') || getters.hasRole('admin')
    if (!output) {
      window.sessionStorage.removeItem('useGenericContext')
    }
    return output
  },
  isSuperAdmin: (state, getters) => {
    return getters.hasRole('superadmin')
  },
  hasRole: (state, getters) => {
    return function (role) {
      if (!isArray(state.roles)) {
        return false
      }
      return state.roles.indexOf(role) > -1
    }
  },
  getCurrentUser: (state, getters) => {
    return state.user
  },
  getCurrentUserRecord: (state, getters, rootState, rootGetters) => {
    rootState.DS.AppUser[state.user.id]
    return store.get('AppUser', state.user.id)
  },
  getCurrentDomain: (state, getters) => {
    return state.user.domain || ''
  },
  isConnecting: (state, getters) => {
    return state.connecting
  },
  isAuthenticated: (state, getters) => {
    return state.user.authenticated
  },
  getCurrentCompanyId: (state, getters) => {
    if (getters.CAN('grains')) {
      return undefined
    }
    return state.currentCompany || storage.getItem('user.currentCompany')
  },
  getPermissions: (state, getters, rootState) => {
    let permissions = []

    const cR = map(state.currentContextRoles, o => `context_${o}`)
    permissions = [].concat(permissions, cR)

    if (getters.isContextAdmin || getters.isAdmin || getters.isSuperAdmin) {
      return [...permissions, ...state.roles.map(role => `APP_${role}`)]
    }

    if (!getters.getCurrentCompanyId) {
      return []
    }

    rootState.DS.CompanyUser
    const companyUsers = store.filter('CompanyUser', { userId: state.user.id })
    const cUR = uniq(map(filter(companyUsers, o => {
      return includes(ALLOWED_ROLES, o.role) && o.companyId === getters.getCurrentCompanyId
    }), el => {
      return el.role ? `client_${el.role}` : undefined
    }))
    permissions = [].concat(permissions, cUR)
    return permissions
  },
  getRoles: (state, getters) => {
    return state.roles
  },
  getAllowedCompanies: (state, getters, rootState, rootGetters) => {
    rootState.DS.CompanyUser
    const companyUsers = store.filter('CompanyUser', { userId: state.user.id })

    let allowedCompanies = compact(uniq(map(filter(companyUsers, companyUser =>
      includes(ALLOWED_ROLES, companyUser.role),
    ), property('companyId'))))

    allowedCompanies = filter(allowedCompanies, company =>
      get(store.get('Company', company), 'contextId') === rootGetters['context/id'],
    )
    if (allowedCompanies.length === 0) {
      return []
    }
    const company = storage.getItem('user.currentCompany')

    if (!company || !includes(allowedCompanies, company)) {
      storage.removeItem('user.currentCompany')
    }

    return store.filter('Company', { where: { _id: { in: allowedCompanies } }, orderBy: 'name' })
  },
}
const actions = {
  // Send a request to the login URL and save the returned JWT
  login ({ dispatch, commit }, credentials, redirect) {
    commit('removeNonPersistantAlerts')
    commit('AUTHENTICATED', false)
    commit('CONNECTING', true)
    const cred = {
      identifier: credentials.mail,
      password: credentials.pwd,
    }
    credentials = cred

    axios.post(LOGIN_URL(), cred, vueHttpConfig())
      .then((response) => {
        const { access_token } = response.data
        // eslint-disable-next-line n/no-deprecated-api
        const data = JSON.parse(Buffer.from(access_token.split('.')[1], 'base64').toString())
        // @info - Vérifier les objets retenus en localStorage / Souci de sécurité?
        storage.setItem('id_token', access_token)
        updateAdapter()
        storage.setItem('id_user', data.sub)
        storage.setItem('ttl_token', data.exp - data.iat)
        storage.setItem('created_token', new Date(data.iat * 1000))
        // on purge les notifications
        commit('refreshAlerts')
        // on connect l'utilisateur
        dispatch('currentUser')

        set(store.getAdapter().http.defaults.headers, 'common.Authorization', `Bearer ${access_token}`)

        if (redirect) {
          router.push({ path: redirect })
        }
      }, (error) => {
        console.error(`API ERROR [${error.status}] (${error.message})`)
        dispatch('addAlert', { icon: true, type: 'error', message: 'Erreur de connexion', persist: false, duration: 50000 })

        // logout
        commit('AUTHENTICATED', false)
        commit('CONNECTING', false)
      })
  },

  loginAs ({ dispatch, commit }, userId) {
    const token = storage.getItem('id_token')

    const { headers } = vueHttpConfig()

    headers.Authorization = `Bearer ${token}`

    axios.post(LOGIN_AS_URL(), {
      userId,
    }, { headers })
      .then((response) => {
        const { access_token, redirect } = response.data
        if (redirect) {
          window.open(`${redirect}?idT=${access_token}`)
        } else {
          commit('removeNonPersistantAlerts')
          commit('AUTHENTICATED', false)
          commit('CONNECTING', true)
          // eslint-disable-next-line n/no-deprecated-api
          const data = JSON.parse(Buffer.from(access_token.split('.')[1], 'base64').toString())
          // @info - Vérifier les objets retenus en localStorage / Souci de sécurité?
          storage.setItem('id_token', access_token)
          updateAdapter()
          storage.setItem('id_user', data.sub)
          storage.setItem('ttl_token', data.exp - data.iat)
          storage.setItem('created_token', new Date(data.iat * 1000))
          // on purge les notifications
          commit('refreshAlerts')
          // on connect l'utilisateur
          dispatch('currentUser')

          set(store.getAdapter().http.defaults.headers, 'common.Authorization', `Bearer ${access_token}`)
        }
      }, (error) => {
        console.error(`API ERROR [${error.status}] (${error.message})`)
        dispatch('addAlert', { icon: true, type: 'error', message: 'Erreur de connexion', persist: false, duration: 50000 })

        // logout
        commit('AUTHENTICATED', false)
        commit('CONNECTING', false)
      })
  },

  softLogout ({ commit }) {
    commit('removeNonPersistantAlerts')
    commit('refreshAlerts')
    commit('RESET')
    commit('AUTHENTICATED', false)
    commit('CONNECTING', false)
    router.push({ path: '/login' })
  },
  logout ({ commit }) {
    commit('removeNonPersistantAlerts')
    commit('refreshAlerts')
    commit('RESET')
    for (const model in store.get) {
      if (model !== 'Context') {
        try {
          store.removeAll(model)
        } catch (error) {
          // error
        }
      }
    }
    commit('AUTHENTICATED', false)
    commit('CONNECTING', false)
    router.go({ path: '/login' })
  },
  updatePermission ({ commit, dispatch, state, getters }) {
    if (!includes(map(getters.getAllowedCompanies, property('id')), getters.getCurrentCompanyId)) {
      commit('SET_CURRENT_COMPANY', null)
    }
    if (getters.getAllowedCompanies.length === 1) {
      commit('SET_CURRENT_COMPANY', getters.getAllowedCompanies[0].id)
    }
    const roles = map((getters.getCurrentUserRecord?.roles || []), o => o.name)
    if (getters.getCurrentUserRecord?.contextRoles?.[getters['context/id']]?.isContextAdmin) {
      roles.push('contextAdmin')
    }
    commit('SET_USER_ROLES', roles)

    const currentContextRoles = []

    if (getters.getCurrentUserRecord?.contextRoles?.[getters['context/id']]?.isContributor) {
      currentContextRoles.push('CONTRIBUTOR')
      currentContextRoles.push(`CONTRIBUTOR_${getters['context/id']}`)
    }
    if (getters.getCurrentUserRecord?.contextRoles?.[getters['context/id']]?.isContextAdmin) {
      currentContextRoles.push('CONTEXT_ADMIN')
      currentContextRoles.push(`CONTEXT_ADMIN_${getters['context/id']}`)
    }
    if (!getters['context/isGeneric'] && getters['context/get']('settings.allowAccessToCertificationFeature')) {
      currentContextRoles.push('allowAccessToCertificationFeature')
    }
    if (!getters['context/isGeneric'] && getters['context/get']('settings.allowAccessToInvitationCodeFeature')) {
      currentContextRoles.push('allowAccessToInvitationCodeFeature')
    }
    commit('SET_USER_CONTEXT_ROLES', currentContextRoles)

    if (getters.getRoles.length > 0) {
      commit('SET_CURRENT_DOMAIN', 'grains')
    } else if (getters.getAllowedCompanies.length > 0) {
      commit('SET_CURRENT_DOMAIN', 'client')
    } else {
      commit('SET_CURRENT_DOMAIN', 'public')
    }
  },
  loadUserFromToken ({ commit, dispatch, state, getters }, token) {
    commit('removeNonPersistantAlerts')
    storage.setItem('id_token', token)
    updateAdapter()
    axios.get(`${ME_URL()}?access_token=${token}`, vueHttpConfig()).then(
      (response) => {
        try {
          storage.setItem('id_token', token)
          updateAdapter()
          const body = response.data
          delete body.roles
          const user = store.add('AppUser', body)
          const u = user
          u.token = token
          u.id = user.id
          u.permissions = []
          // en mettant ça .. nous sommes connecté
          storage.setItem('id_user', u.id)
          commit('SET_USER', u)
        } catch (error) {
          console.error(error)
        }
      },
      (error) => {
        if (error.status === 403) {
          dispatch('addAlert', { icon: true, type: 'info', message: 'Lien expiré', persist: true, duration: 5000 })
        } else {
          dispatch('addAlert', { icon: true, type: 'info', message: 'Vous devez vous reconnecter.', persist: false, duration: 5000 })
        }
        dispatch('softLogout')
      },
    )
  },
  loadUserPermissions  ({ commit, dispatch, state }, user) {
    let redirect = window.sessionStorage.getItem('main.request.url') || '/'
    redirect = redirect === '/login' ? '/' : redirect
    user.loadRolesAndPermission().then(user => {
      window.user = user
      commit('SET_USER', user)
      commit('AUTHENTICATED', true)
      dispatch('updatePermission')
      if (redirect) {
        router.push({ path: redirect }, () => {
          commit('CONNECTING', false)
        }, () => {
          commit('CONNECTING', false)
        })
      } else {
        commit('CONNECTING', false)
      }
    })
    return state.user
  },
  loadUser ({ commit, dispatch, state }) {
    return store.getMapper('AppUser').me().catch(err => {
      console.error(err)
      throw new Error('RECONNECT')
    }).then(me => {
      if (get(me, 'data._id') === storage.getItem('id_user')) {
        return me.data
      } else {
        throw new Error('RECONNECT')
      }
    }).then(me => {
      return store.find('AppUser', storage.getItem('id_user'), { force: true })
    }).then(user => {
      const u = user
      u.token = storage.getItem('id_token')
      u.id = storage.getItem('id_user')
      u.permissions = []
      dispatch('loadUserPermissions', user)
      return state.user
    }).catch(err => {
      console.error(err)
      commit('AUTHENTICATED', false)
      commit('CONNECTING', false)
      if (err === 'RECONNECT') {
        dispatch('addAlert', { icon: true, type: 'error', message: 'Erreur lors du chargement de votre compte, veuillez recharger la page et réessayer.', persist: false })
      } else {
        dispatch('addAlert', { icon: true, type: 'info', message: 'Vous devez vous reconnecter.', persist: false, duration: 5000 })
      }
      return false
    })
  },
  hotSwitchUserFromLocalStorage ({ commit, dispatch, state }) {
    commit('RESET')
    for (const model in store.get) {
      if (model !== 'Context') {
        try {
          store.removeAll(model)
        } catch (error) {
          // error
        }
      }
    }
    return dispatch('currentUser')
  },
  hotSwitchUser ({ commit, dispatch, state }, payload) {
    if (!payload) {
      throw new Error('Données manquantes pour le changement d\'utilisateur')
    }
    ['id_token', 'id_user', 'user.currentCompany'].forEach(key => {
      if (payload[key]) {
        storage.setItem(key, payload[key])
      }
    })
    return dispatch('hotSwitchUserFromLocalStorage')
  },
  currentUser ({ commit, dispatch, state }) {
    if (!storage.getItem('id_token')) {
      commit('CONNECTING', false)
      return false
    }

    if (!state.user.token || storage.getItem('id_token') !== state.user.token) {
      updateAdapter()
      dispatch('loadUser')
    } else {
      return state.user
    }
  },
  // Change domain
  changeCompany ({ commit, dispatch, state }, companyId) {
    commit('SET_CURRENT_COMPANY', companyId)
    dispatch('updatePermission')
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
