import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import resolveDeps from '../getDeps.js'
import store from '@/api'
import { compact, isUndefined, map, uniqBy } from 'lodash'
import moment from 'moment'
import utils from '@/helpers/utils.js'
import { ROLES } from '../enums/roles.js'
class AppUserRecord extends Record {
  get editPath () {
    return { name: 'UserEdit', params: { id: this._id } }
  }

  get companies () {
    return compact(uniqBy(this.companyUsers.map(el => el.company), 'id'))
  }

  set companies (companies) {
    console.log('AppUser.companies.set() not set')
  }

  affectToCompany ({ companyId, role }) {
    if (companyId && role && !store.filter('CompanyUser', { where: { userId: this._id, companyId, role } }).length) {
      return store.create('CompanyUser', {
        userId: this._id,
        companyId,
        role,
      })
    } else {
      return Promise.resolve()
    }
  }

  affectToClass ({ classId, role }) {
    if (classId && role && !store.filter('ClassUser', { where: { userId: this._id, role, classId } }).length) {
      return store.create('ClassUser', {
        userId: this._id,
        classId,
        role,
      }, { forceRelationshipsInAttributes: true })
    } else {
      return Promise.resolve()
    }
  }

  loadRolesAndPermission () {
    return store.findAll('RoleUser', { filter: { principalId: this.id }, populate: { path: 'role' } }, { force: true })
      .catch(e => {
        console.error(e)
      })
      .then(roleUsers => {
        return store.findAll('CompanyUser', { filter: { userId: this.id }, populate: { path: 'company', populate: { path: 'wallets' } } }, { force: true })
      }).then(CompanyUsersResponse => {
        return this
      })
  }

  loadRoles () {
    // Chargement des ROLES ADMIN
    return store.findAll('RoleUser', { filter: { principalId: this.id }, populate: { path: 'role' } }, { force: true })
      .then(data => {
        return map(data, 'role')
      })
  }

  get id () {
    return this._id
  }

  set id (val) {
    this._id = val
  }

  get createdSince () {
    return this.createdAt ? moment(this.createdAt).fromNow() : '-'
  }

  get age () {
    return this.birthday ? moment().diff(this.birthday, 'years') : '-'
  }

  get roles () {
    const roleUsers = store.getAll('RoleUser', this.id, { index: 'principalId' })
    return roleUsers.map((roleUsers) => {
      return roleUsers.role
    }).filter((role) => role)
  }

  get displayName () {
    if (this.firstname || this.lastname) {
      return [this.firstname, this.lastname].filter(Boolean).join(' ')
    }
    if (this.email) {
      return this.email
    }
    return this.username
  }

  get getDeps () {
    const promises = {
      Class: store.findAll('ClassUser', { filter: { userId: this.id }, populate: { path: 'class' } }, { force: true, raw: true }).then((response) => {
        const classIds = response.data.map(c => c.classId)
        const classes = store.filter('Class', { where: { _id: { in: classIds } } })
        return { data: classes, count: +response.headers['x-model-count'] }
      }),
      Company: store.findAll('CompanyUser', { filter: { userId: this.id }, populate: { path: 'company' } }, { force: true, raw: true }).then((response) => {
        const companyIds = response.data.map(c => c.companyId)
        const companies = store.filter('Company', { where: { _id: { in: companyIds } } })
        return { data: companies, count: +response.headers['x-model-count'] }
      }),
    }
    return resolveDeps(promises)
  }

  userClass (filter = {}, roles = [], options = {}) {
    return this.constructor.mapper.userClass(this.id, {
      params: {
        roles: JSON.stringify(roles),
        filter: JSON.stringify(filter),
        options: JSON.stringify(options),
        populate: JSON.stringify([{
          path: 'classUsers',
          match: {
            role: { $in: [ROLES.TEACHER, ROLES.OWNER, ROLES.CERTIFIER] },
          },
          populate: { path: 'user' },
        },
        {
          path: 'classPrograms',
          populate: { path: 'program' },
        }]),
      },
    }).then(response => {
      response.data = response.data.map(el => {
        return store.add('Class', el)
      })
      return response
    })
  }

  records (from, to) {
    const params = { filter: { userId: this.id } }
    if (!isUndefined(to)) {
      params.to = moment(to).toISOString()
    }
    if (!isUndefined(from)) {
      params.from = moment(from).toISOString()
    }
    return this.constructor.mapper.records(null, { params }).then(response => {
      return response.data
    })
  }

  async findLastRecord () {
    const params = { filter: { userId: this.id }, options: { skip: 0, limit: 1, sort: '-date' } }
    const response = await this.constructor.mapper.records(null, { params })
    return response.data[0] || null
  }

  contact (message, receiveCopy, subject, attachments = []) {
    message = utils.textToHtml(message)
    return this.constructor.mapper.contact(this.id, { data: { message, receiveCopy, subject, attachments } })
  }

  notifyFiles (message, fileIds) {
    return this.constructor.mapper.notifyFiles(this.id, { data: { message, fileIds } })
  }

  contactGroup (message, subject, group, attachments = []) {
    message = utils.textToHtml(message)
    const formattedMessage = message.split('\n').join('</p><p>')
    const mergedMessage = `<p><strong>${subject}<strong></p><hr/><p>${formattedMessage}</p><hr/><p>envoyé au groupe <strong>${group}</strong></p>`
    return this.constructor.mapper.contactSupport(
      this.id,
      {
        data: {
          group,
          message: mergedMessage,
          receiveCopy: true,
          attachments,
        },
      },
    )
  }
}

const AppUserSchema = new Schema({
  title: 'AppUserSchemaTitle',
  description: 'AppUser\'s Schema',
  type: 'object',
  properties: {
    username: {
      type: 'string',
      form: {
        rules: [
          { required: true, message: 'Entrez un pseudonyme', trigger: 'blur' },
          { min: 3, max: 60, message: 'Taille requise entre 3 et 60 caractères', trigger: 'blur' },
        ],
      },
    },
    firstname: {
      type: 'string',
      form: {
        rules: [
          { required: true, message: 'Entrez un prénom', trigger: 'blur' },
          { min: 3, max: 60, message: 'Taille requise entre 3 et 60 caractères', trigger: 'blur' },
        ],
      },
    },
    supportGroups: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    lastname: {
      type: 'string',
      form: {
        rules: [
          { required: true, message: 'Entrez un nom', trigger: 'blur' },
          { min: 3, max: 60, message: 'Taille requise entre 3 et 60 caractères', trigger: 'blur' },
        ],
      },
    },
    email: {
      type: ['string', 'null'],
      form: {
        rules: [
          { required: false, type: 'email', message: 'Saisissez un e-mail valide', trigger: 'blur' },
          { min: 3, max: 254, message: 'Taille requise entre 3 et 254 caractères', trigger: 'blur' },
        ],
      },
    },
    function: {
      form: {
        rules: [
          { min: 3, max: 254, message: 'Taille requise entre 3 et 254 caractères', trigger: 'blur' },
        ],
      },
    },
    phone: {
      form: {
        rules: [
          { min: 7, max: 20, message: 'Taille requise entre 7 et 20 caractères', trigger: 'blur' },
        ],
      },
    },
    birthday: {
      type: ['string', 'null'],
      form: {
        rules: [
          { required: true, message: 'Merci renseigné votre date de naissance', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const today = new Date() // Get the current date
              const birthdate = new Date(value) // Convert the birthdate string to a Date object
              const ageDate = new Date(today.getTime() - birthdate.getTime())
              const years = ageDate.getUTCFullYear() - 1970
              if (years < 14) {
                return callback(new Error('Vous devez avoir 14 ans pour vous inscrire'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
    },
    isContributor: {
      type: ['boolean', 'null'],
      track: false,
    },
    isContextAdmin: {
      type: ['boolean', 'null'],
      track: false,
    },
    password: {
      type: ['string', 'null'],
      form: {
        rules: [
          { min: 6, max: 254, message: 'Taille requise entre 6 et 254 caractères', trigger: 'blur' },
          {
            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,
            message: 'Doit contenir au moins : une majuscule, une minuscule, un chiffre et un caractère spécial',
            trigger: 'blur',
          }, {
            validator: (rule, value, callback) => {
              if (!(value && value.length > 0)) {
                return callback(new Error('Entrez votre nouveau mot de passe pour le changer'))
              } else if (/\s/.test(value)) {
                return callback(new Error('Les espaces ne sont pas autorisés'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
    },
    avatarId: {
      type: ['string', 'null'],
    },
    legal: {
      type: 'object',
      properties: {
        cguAcceptedVersion: {
          type: 'string',
        },
        cguAcceptedAt: {
          type: 'string',
        },
      },
    },
    contributionLevel: {
      type: ['integer', 'null'],
    },
    metas: {
      type: 'object',
      additionalProperties: true,
    },
  },
  required: ['username'],
})

export const AppUser = store.defineMapper('AppUser', {
  schema: AppUserSchema,
  endpoint: 'users',
  supportGroups: ['CONTRIBUTION', 'SUPPORT'],
  relations: {
    hasOne: {
      CompanyInvitationCode: {
        loadAllDatasWhenEditing: false,
        localField: 'companyInvitationCode',
        foreignKey: 'userId',
      },
    },
    hasMany: {
      CompanyUser: {
        loadAllDatasWhenEditing: false,
        localField: 'companyUsers',
        foreignKey: 'userId',
      },
      ClassUser: {
        loadAllDatasWhenEditing: false,
        localField: 'classUsers',
        foreignKey: 'userId',
      },
      RoleUser: {
        loadAllDatasWhenEditing: false,
        foreignKey: 'principalId',
        localField: 'role-users',
      },
      Lesson: {
        loadAllDatasWhenEditing: false,
        foreignKey: 'userId',
        localField: 'lessons',
      },
      Record: {
        loadAllDatasWhenEditing: false,
        foreignKey: 'userId',
        localField: 'PiScoreRecords',
      },
    },
  },
  recordClass: AppUserRecord,
})
const transformUrlForContactForms = function (request) {
  // http://sub.domaine.ext/folder/folder/ON_SUPPRIME_ICI
  request.url = request.url.replace(/\/[^/]*\/?$/, '')
  return request
}
addActions({
  me: {
    pathname: 'me',
    method: 'GET',
  },
  loginAs: {
    pathname: 'login-as',
    method: 'POST',
  },
  records: {
    endpoint: 'records',
    request: transformUrlForContactForms,
    method: 'GET',
  },
  userClass: {
    pathname: 'classes-with-role',
    method: 'GET',
  },
  contact: {
    endpoint: 'users/contact',
    request: transformUrlForContactForms,
    method: 'POST',
  },
  notifyFiles: {
    endpoint: 'users/contact',
    request: transformUrlForContactForms,
    method: 'POST',
  },
  exportCsv: {
    pathname: 'export/csv',
    method: 'GET',
  },
  contactSupport: {
    endpoint: 'users/contact/support',
    request: function (request, b, c) {
      if (!request.data || !request.data.group) {
        throw new Error('Aucun groupe de destination défini')
      }
      request.url = `${request.url.replace(/(\/[^/]*){2}\/?$/, '')}/${request.data.group}`
      // throw new Error('STOP')
      return request
    },
    method: 'POST',
  },
})(AppUser)
