
import store from '@/api'
import { compact, filter, forOwn, get, isNull, map } from 'lodash'
import { ROLES } from '@/api/enums/roles'

const state = () => ({
  to: null,
  classId: null,
  group: null,
  companyId: null,
})

// getters
const getters = {
  contactUserRecipients (state, getters) {
    let to = []
    if (getters.contactUserIsOneUserMode) {
      to = [store.get('AppUser', state.to)]
    } else if (getters.contactUserIsClassMode) {
      getters.DSClass[state.classId]
      const _class = store.get('Class', state.classId) || {}

      const classUsers = get(_class, 'classUsers', [])
      const students = filter(classUsers, classUser => {
        return classUser.role === ROLES.STUDENT
      })
      return map(students, 'user')
    } else if (getters.contactUserIsGroupMode) {
      to = store.filter('AppUser', { where: { id: { in: state.group } } })
    }
    return compact(to)
  },
  contactUserTo (state, getters) {
    return state.to
  },
  contactUserClassTo (state, getters) {
    return state.classId
  },
  contactUserGroupTo (state, getters) {
    return state.group
  },
  contactCompanyId (state, getters) {
    return state.companyId
  },
  contactUserIsOneUserMode (state, getters) {
    return !isNull(state.to)
  },
  contactUserIsClassMode (state, getters) {
    return !isNull(state.classId)
  },
  contactUserIsGroupMode (state, getters) {
    return !isNull(state.group)
  },

  contactUserWhom (state, getters) {
    let someone
    if (getters.contactUserIsOneUserMode) {
      someone = get(getters.contactUserRecipients, '[0].displayName')
    }
    if (getters.contactUserIsClassMode) {
      someone = 'tous les participants'
    }
    if (getters.contactUserIsGroupMode) {
      someone = `tous les utilisateurs (x${getters.contactUserRecipients.length})`
    }
    return someone
  },
  contactUserSubmitWho (state, getters) {
    let something
    if (getters.contactUserIsOneUserMode) {
      something = get(getters.contactUserRecipients, '[0].displayName')
    }
    if (getters.contactUserIsClassMode) {
      const className = get(store.get('Class', state.classId), 'name')
      something = `les participants du parcours ${className}`
    }
    if (getters.contactUserIsGroupMode) {
      something = `plusieurs utilisateurs (x${getters.contactUserRecipients.length})`
    }
    return something
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  RESET (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
  CONTACTFORM_SET_TO (state, to) {
    state.to = to
  },
  CONTACT_CLASS_FORM_SET_TO (state, classId) {
    state.classId = classId
  },
  CONTACT_GROUP_FORM_SET_TO (state, group) {
    state.group = group
  },
  CONTACT_SET_COMPANY (state, companyId) {
    state.companyId = companyId
  },
  CONTACTFORM_HIDE (_state) {
    const initialState = state()
    forOwn(_state, (value, key) => {
      _state[key] = initialState[key]
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
