import { Schema } from 'js-data'
import store from '@/api'

const ProgramCompanySchema = new Schema({
  title: 'ProgramCompanyschemaTitle',
  description: 'Schema for ProgramsCourse Records.',
  type: 'object',
  properties: {
    contextId: {
      type: ['null', 'string'],
    },
    programId: {
      type: 'string',
    },
    companyId: {
      type: 'string',
    },
  },
})
export const ProgramCompany = store.defineMapper('ProgramCompany', {
  schema: ProgramCompanySchema,
  endpoint: 'program-companies',
  relations: {
    belongsTo: {
      Program: {
        loadAllDatasWhenEditing: false,
        localField: 'program',
        foreignKey: 'programId',
      },
      Company: {
        loadAllDatasWhenEditing: false,
        localField: 'company',
        foreignKey: 'companyId',
      },
    },
  },
})
