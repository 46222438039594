
export default (channel) => {
  return {
    props: [],
    data () {
      return {
        [channel]: channel,
      }
    },
    methods: {
      [`${channel}_add`] (alert) {
        this.$store.commit('ADD_ALERT_IN_CHANNEL', {
          channel,
          alert,
        })
      },
      [`${channel}_clear`] () {
        this.$store.commit('CLEAR_ALERTS_FROM_CHANNEL', {
          channel,
        })
      },
      [`${channel}_remove`] (index) {
        this.$store.commit('REMOVE_ALERT_FROM_CHANNEL', {
          channel,
          index,
        })
      },
    },
  }
}
